import React from "react";
import { Link, graphql } from "gatsby";
import SiteLayout from "@layouts/site-layout";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/section_new_styles/new_website.scss";
import hero_img from "../assets/section_new_images/hero.svg";
import playIcon from "../assets/section_new_images/ArrowCircleRight.png";
import stars from "../assets/section_new_images/stars.svg";
import innovation from "../assets/section_new_images/innovation.svg";

import individual from "../assets/section_new_images/individual_plan.png";

import CreateProgress from "../components/CreateProgress";
import OurStory from "../components/OurStory";
import PartnerWith from "../components/PartnerWith";
import NewTestimonials from "../components/NewTestimonials";
import DownloadApp from "../components/DownloadApp";
import Animations from "../components/Animations";
import Dashboard from "../components/Dashboard";
import IndustryLeaders from "../components/IndustryLeaders";
import WhyChoosUs from "../components/WhyChoosUs";
import Gamify from "../components/Gamify";

export default class ome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    var a = window.ChatGen || [];
    if (!a.loaded) {
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://app.chatgen.ai/cmp/chat-widget/bot.js";
      var fs = document.getElementsByTagName("script")[0];
      fs.parentNode.insertBefore(s, fs);
      s.onload = function() {
        var yourKey = "dnPALJkR";
        var widgetKey = { widget_key: yourKey };
        window.ChatGen.init(widgetKey);
      };
    }
  }

  render() {
    let description = `STEPapp, created by a team of 400+ IITians and Doctors after extensive research, is a unique
      e-learning software product that provides conceptual content delivery in a gamified format. Our
      team comes with deep experience and expertise gathered over many years of teaching and
      training students from various socio-economic strata of the country, preparing them for various
      reputed national and international competitive exams, and turning them into the best and
      brightest this country has ever seen.`;
    let img = `img/stepapp_logo.png`;
    return (
      <SiteLayout title="Home" description={description} image={img}>
        <div
          className="home_middle_sec top_margin_comn px-3 py-2"
          style={{ backgroundColor: "#4545af" }}
          id='hero'
        >
          <div className="hero">
            <div className="step_container">
              <div className="info-column">
                <p
                  className="mb-3 mb-md-5 hero-title text-white font-weight-bold"
                  style={{ fontSize: "clamp(2rem, 4.5vw, 3.5rem)", fontWeight: 800}}
                >
                  Revolutionising
                  <br />
                  <span
                    className="hero-subtitle"
                    style={{ fontSize: "clamp(1.8rem, 4.5vw, 3rem)" }}
                  >
                    the world of<br />education&nbsp;!
                  </span>
                </p>
                {/* <p >the world of education !</p> */}
                <a href='https://learn.stepapp.in/login' target="_blank" className="cta-button py-2 py-md-3">
                  Play Game
                  <span
                    className="cta-icon"
                    style={{ backgroundImage: `url(${playIcon})` }}
                  ></span>
                </a>
              </div>
              <div className="image-column">
                <img
                  src={hero_img}
                  alt="Company Representation"
                  className="company-image"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Why Choose Us */}
        <WhyChoosUs></WhyChoosUs>

        {/* Lets Gamify Education */}
        <Gamify></Gamify>

        {/*  Testimonials */}
        <NewTestimonials></NewTestimonials>

        {/* Industry Leader */}
        <IndustryLeaders></IndustryLeaders>

        {/* Donwload App */}
        <DownloadApp></DownloadApp>
        <div className="container-lg my-2">
          <hr />
        </div>

        {/* Animations */}
        <Animations></Animations>

        {/* Dashboard */}
        <Dashboard></Dashboard>

        <div className="innovation-section my-3 px-2 px-md-5" id='innovation'>
          <div className="play-earn-section_container" style={{gap: '1rem'}}>
            <img
              src={innovation}
              alt="innovation Representation"
              className="innovation-image"
            />
            <h1 className="innovation-title font-weight-bold" style={{fontSize: 'clamp(2rem, 2.5vw, 4rem)'}}>
              World Class Innovation Programs for Curious Young Brains
            </h1>
            <p className="innovation-subtitle" style={{fontSize: 'clamp(1.2rem, 2.5vw, 2.5rem)'}}>
              STEPapp Skills will give you world-class innovative programs in a
              variety of fields to cater to the inquisitive minds of every child
              looking to take a leap toward any sector.
            </p>
            <Link to='/exploremore' target="_blank" className="innovation-cta-button px-4 py-2">
              Explore More
            </Link>
          </div>
        </div>

        <div className="pricing-plan-section" id='pricingPlan'>
          <div className="pricing-plan-section_container">
            <h1 className="pricing-plan-title" style={{ fontSize: 'clamp(1.5rem, 2.5vw, 3rem)', fontWeight: 800 }}>Pricing Plan</h1>

            <div className="plan-items">
              <div className="plan-item">
                <div className="plan-item-background"></div>
                <div className="plan-item-section">
                  <h1 className="plan-item-title font-weight-bold">Individual</h1>
                  <img
                    src={individual}
                    alt="Plan Item Representation"
                    className="plan-item-image"
                  />
                  <a href='https://learn.stepapp.in/login' target="_blank"  className="plan-item-cta-button">Buy Now</a>
                  <ul>
                    <li>For one person</li>
                    <li>A Gamified Learning App</li>
                    <li>Animations to make you visualize every concept</li>
                    <li>Personalized Reports</li>
                    <li>Engaging quizzes to assess your learning</li>
                  </ul>
                  <div className="line"></div>
                  <h1 className="plan-price-title">₹ 5000 / year</h1>
                </div>
              </div>
              <div className="plan-item">
                <div
                  style={{ backgroundColor: "#8A8E10" }}
                  className="plan-item-background"
                ></div>
                {/* <div style={{ backgroundColor: '#8A8E10' }} className='plan-item-background'></div> */}
                <div
                  style={{ backgroundColor: "#FBFDD1" }}
                  className="plan-item-section"
                >
                  <h1 className="plan-item-title font-weight-bold">Organisation</h1>
                  <img
                    src={stars}
                    alt="Plan Item Representation"
                    className="plan-item-image"
                  />
                  <Link
                  to='/#contact'
                    style={{ backgroundColor: "#8A8E10" }}
                    className="plan-item-cta-button"
                  >
                    Contact Us
                  </Link>
                  <ul>
                    <li>Everything in Individual Plan</li>
                    <li>Policy Maker Dashboard</li>
                    <li>Teachers Dashboard</li>
                    <li>Principal Dashboard</li>
                    <li>
                      Teachers can assign homework and quizzes to the Class
                    </li>
                    <li>Substantially Low Pricing</li>
                  </ul>
                  <div
                    style={{ backgroundColor: "#8A8E10" }}
                    className="line"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="play-earn-section" id='playEarn'>
          <div className="play-earn-section_container px-3 px-md-5">
            <img
              src={stars}
              alt="Stars Representation"
              className="stars-image"
            />
            <h1 className="play-earn-title" style={{fontSize: 'clamp(2rem, 2.5vw, 4rem)', fontWeight: 800}}>Play & earn rewards</h1>
            <p className="play-earn-subtitle" style={{fontSize: 'clamp(1rem, 2.5vw, 1.5rem)'}}>
              STEPapp Skills will give you world-class innovative programs in a
              variety of fields to cater to the inquisitive minds of every child
              looking to take a leap toward any sector.
            </p>
            <a href='https://learn.stepapp.in/login' target="_blank" className="play-earn-cta-button">Play Now</a>
          </div>
        </div>
        <PartnerWith />
        <OurStory />
        <CreateProgress />
      </SiteLayout>
    );
  }
}
