import React from 'react'
import mobile_app from '../assets/section_new_images/mobile_app.png'
import letGame1 from '../assets/section_new_images/letGame1.png'
import letGame2 from '../assets/section_new_images/letGame2.png'
import letGame3 from '../assets/section_new_images/letGame3.png'


const Gamify = () => {
   return (
      <section className='d-flex justify-content-center' id='gamify'>
         <div className='container d-flex flex-column px-4 py-3'>

            <div>
               <h1 className='text-black text-center text-md-left ' style={{ fontSize: 'clamp(1.5rem, 2.5vw, 3rem)', fontWeight: 800 }}>Let’s Gamify Education !</h1>
            </div>
            <div className='row'>
               <div className='col-md-5 col-12 left-side text-center text-md-left'>
                  <div>
                     <h1 className='text-black' style={{ fontSize: 'clamp(1rem, 2.5vw, 2.5rem)', fontWeight: 600 }}>What’s in for you :</h1>
                  </div>
                  <div className='d-flex justify-content-between flex-column py-3'>
                     <div className='d-flex justify-content-md-start justify-content-center '>
                        <a href='https://learn.stepapp.in/login' target="_blank" className="cta-button px-5 py-2">
                           Enroll Now
                        </a>
                     </div>
                     <div className='image-section mt-4'>
                        <img src={mobile_app} className='img-fluid' style={{objectFit: 'contain'}} />
                     </div>

                  </div>
               </div>
               <div className='col-md-7 col-12 right-side'>
                  <div className='d-flex flex-column justify-content-around p-md-4 p-2 h-100 my-5' >
                     <div className='row p-4 my-3 ' style={{
                        borderRadius: 40,
                        background: '#FAE0F3'
                     }}>
                        <div className='col-md-4 col-12 d-flex justify-content-center justify-content-md-start'>
                              <img src={letGame1} className='img-fluid stp-image' />
                        </div>
                        <div className='col-md-8 col-12 text-md-left text-center'>
                           <h2 style={{ color: '#812167', fontSize: 'clamp(1.5rem, 2.5vw, 2rem)'}}>Students</h2>
                           <div >
                              <p style={{ fontSize: '1rem' }}>Enjoyable learning in a <strong>gamified format </strong>
                                 Mentorship and <strong>scholarship rewards</strong>
                                 Personalized learning <strong>curve shaped using AI</strong></p>
                           </div>
                        </div>
                     </div>

                     <div className='row p-4 my-2' style={{
                        borderRadius: 40,
                        background: '#FBFDD1'
                     }}>
                        <div className='col-md-4 col-12 d-flex justify-content-center justify-content-md-start'>
                              <img src={letGame2} className='img-fluid stp-image' />
                        </div>
                        <div className='col-md-8 col-12 text-md-left text-center'>
                           <div> <h2 style={{ color: '#5F6207',fontSize: 'clamp(1.5rem, 2.5vw, 2rem)'}}>Teachers</h2></div>
                           <div >
                              <p style={{ fontSize: '1rem' }}>Access to a helping hand in the form of a homework and revision app
                                 Augment classroom teaching through the intensive Animations</p>
                           </div>
                        </div>
                     </div>

                     <div className='row p-4  my-3 ' style={{
                        borderRadius: 40,
                        background: '#D0FDE0'
                     }}>
                        <div className='col-md-4 col-12 d-flex justify-content-center justify-content-md-start'>
                              <img src={letGame3} className='img-fluid stp-image' />
                        </div>
                        <div className='col-md-8 col-12 text-md-left text-center'>
                           <div> <h2 style={{ color: '#0A662A',fontSize: 'clamp(1.5rem, 2.5vw, 2rem)' }}>Parents</h2></div>
                           <div >
                              <p className='' style={{ fontSize: '1rem' }}>Access to timely reports
                                 Calculated based on speed, accuracy and number of attempts</p>
                           </div>
                        </div>
                     </div>

                  </div>
               </div>
            </div>
         </div>
      </section>
   )
}

export default Gamify