import React, { useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../assets/section_new_styles/new_testimonial.scss'
import swayam from '../assets/section_new_images/swayam.webp'
import sanat from '../assets/section_new_images/sanat.webp'
import Mahek from '../assets/section_new_images/mahek.webp'
import meet from '../assets/section_new_images/meet.webp'
import nidhi from '../assets/section_new_images/nidhi.webp'
import ayush from '../assets/section_new_images/ayush.webp'
import sourik from '../assets/section_new_images/sourik.webp'
import agam from '../assets/section_new_images/agam.webp'
import shaurya from '../assets/section_new_images/shaurya.webp'

const testiminialsData = [
    {
        id: 0,
        name: "Swayam Gupta",
        img: swayam,
        grade: "11th",
        title: "A Gamified Learning Revolution",
        subtitle: "I have been using STEPapp for a few months now, and I can honestly say it has transformed the way I learn. The app's gamified features and Dashboard have made the process much the process much more enjoyable and I love seeing my progress as I work towards my learning goals.",
        darkColor: '#C3C862',
        lightColor: '#FAFBDA'
    },
    {
        id: "1",
        name: "Sanat Garyali",
        img: sanat,
        grade: "11th",
        title: "Motivating Learning with STEPapp",
        subtitle: "STEPapp has made my studies so much more fun. The app challenges and rewards keep me motivated to hit my learning goals, and the gamified elements make it a lot more enjoyable.",
        darkColor: '#C179DC',
        lightColor:'#F9E4F5'
    },
    {
        id: "2",
        name: "Mahek Savla",
        img: Mahek,
        grade: "11th",
        title: "Gamified and Interactive",
        subtitle: "I have never been one to enjoy learning, but STEPapp has completely changed my prespective. The gamified feature have made studying a fun and exciting experience. I love the feeling of progress. I would recommend this App to anyone looking to improve their education in a fun and engaging way.",
        darkColor: '#51BD79',
        lightColor: '#D7FBE6'
    },
    {
        id: "3",
        name: "Meet Shah",
        img: meet,
        grade: "11th",
        title: "Achieving Learning Goals Made Fun",
        subtitle: "I have been using STEPapp for a few months now, and I can honestly say it has transformed the way I learn. The app's gamified features and Dashboard have made the process much the process much more enjoyable and I love seeing my progress as I work towards my learning goals.",
        darkColor: '#C3C862',
        lightColor: '#FAFBDA'
    },
    {
        id: "4",
        name: "Nidhi Pusalkar",
        img: nidhi,
        grade: "11th",
        title: "Learning and Assessment hand-in-hand",
        subtitle: "I've been using STEPapp for a year now and it has greatly improved my studies. Animated lessons and quizzes are more engaging and help me retain information better. The progress tracking and leaderboard features keep me motivated. I can learn at my own pace and on the go. I highly recommend it for anyone looking to enhance their learning.",
        darkColor: '#C179DC',
        lightColor:'#F9E4F5'
    },
    {
        id: "5",
        name: "Ayush Sinha",
        img: ayush,
        grade: "8th",
        title: "Game-Changing Learning",
        subtitle: "STEPapp was a great step forward in my preparation for competitive examinations. Not only was it extremely enriching in terms of knowledge, but it also allowed me to approach education in the unique sense of a game; which further increased the level of interactivity. From the brilliant designing to the magnificent  animations, STEPapp has provided me with a platform to continue learning when I grow tired of books, and I am extremely happy that I got to experience its wonders.",
        darkColor: '#51BD79',
        lightColor: '#D7FBE6'
    },
    {
        id: "6",
        name: "Sourik Panja",
        img: sourik,
        grade: "8th",
        title: "Transforming Learning with Gamification",
        subtitle: "The gamified learning approach of STEPapp has made Math and Science incredibly enjoyable, turning learning into a fun and interactive experience. The dashboard feature has been a game-changer, allowing me to monitor my progress and receive detailed analysis of my strengths and weaknesses. This personalized insight has helped me prioritize my focus on specific topics, leading to improved academic performance in school.",
        darkColor: '#C3C862',
        lightColor: '#FAFBDA'
    },
    {
        id: "7",
        name: "Agam Khurana",
        img: agam,
        grade: "9th",
        title: "Boosting Academic Performance",
        subtitle: "STEPapp has become my go-to app for revising school concepts, and its impact on my academic performance has been significant. It truly is an excellent tool for gaining conceptual clarity and achieving better results in my studies.",
        darkColor: '#C179DC',
        lightColor:'#F9E4F5'
    },
    {
        id: "8",
        name: "Shaurya Dubey",
        img: shaurya,
        grade: "7th",
        title: "Unlocking Practical Learning",
        subtitle: "This app helps us to understand the concept in a more practical way rather than just reading the bookish language. The animations explain the concept in a practical manner and are interesting too! It also covers the entire syllabus. Overall, STEPapp has successfully made learning Math and Science a fun and enriching journey for me.",
        darkColor: '#51BD79',
        lightColor: '#D7FBE6'
    }
]

const PlanItems = ({ item, index}) => {
    return (
        <div className='plan-items'>
            <div className='plan-item'>
                <div className='plan-item-background' style={{backgroundColor: item.darkColor}}></div>
                <div className='plan-item-section d-flex align-items-center flex-column justify-content-around' style={{backgroundColor: item.lightColor, minHeight: '430px'}}>
                    <img src={item.img} alt="Plan Item Representation" className="plan-item-image" />
                    <div className="title mt-1" style={{fontSize: '1.5rem', lineHeight: 'normal', fontWeight: 700, margin: '0rem -1rem'}}>{item.title}</div>
                    <ReadMore subtitle={item.subtitle} />
                    <div className="title mt-1" style={{fontSize: '1rem', fontWeight: 700}}>- {item.name},<br />{item.grade} grade</div>
                </div>
            </div>
        </div>
    )
}

const ReadMore = ({ subtitle }) => {
    const text = subtitle;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <p className='subtitle' style={{ display: 'inline', width: "100%" }}>
            {isReadMore ? text.slice(0, 150) : text}
            <span onClick={toggleReadMore} className="read-or-hide font-weight-bold">
                {isReadMore ? "...read more" : " show less"}
            </span>
        </p>
    );
};


const NewTestimonials = () => {
    return (
        <div className='testimonial-section my-5' id='testimonials'>
            <div className='testimonial-section_container'>
                <h1 className='font-weight-bold text-center text-black' style={{fontSize: 'clamp(1.5rem, 2.5vw, 3rem)', fontWeight: 800}}>Student testimonials</h1>
                <Slider {...{
                    dots: true,
                    infinite: true,
                    speed: 0,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    responsive: [
                        {
                            breakpoint: 768, // Mobile breakpoint
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            },
                        },
                    ],
                }}>

                    {testiminialsData.map((item, index) => (
                        <div key={index}>
                            <PlanItems item={item} index={index} />
                        </div>
                    ))}


                </Slider>
            </div>
        </div>
    )
}

export default NewTestimonials