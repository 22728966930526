import React, { useState } from "react";
// import Slider from "react-slick";

import video_1 from "../assets/section_new_videos/video_1.mp4";
import video_2 from "../assets/section_new_videos/video_2.mp4";
import video_3 from "../assets/section_new_videos/video_3.mp4";

import video_tn_1 from "../assets/section_new_images/video_tn_1.png";
import video_tn_2 from "../assets/section_new_images/video_tn_2.png";
import video_tn_3 from "../assets/section_new_images/video_tn_3.png";
import video_play_icon from "../assets/section_new_images/video_play_icon.png";
import "../assets/section_new_styles/animations.scss";

// import '../assets/section_new_styles/downloadApp.scss'
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

const slidesData = [
    {
        id: 0,
        image: video_tn_1,
        video: video_1,
    },
    {
        id: 1,
        image: video_tn_2,
        video: video_2,
    },
    {
        id: 2,
        image: video_tn_3,
        video: video_3,
    },
];

const Items = ({ item, index }) => {
    return (
        <div className="py-3">
            <img src={item.img_src} className="img-fluid h-100" />
        </div>
    );
};

const Animations = () => {
    const [videoToPlay, setVideoToPlay] = useState(video_1);

    const handleVideoImgeClick = (value) => {
        setVideoToPlay(value);
    };

    return (
        <section className="my-5" id="animations">
            <div className="container">
                <h1
                    className="text-center mb-3"
                    style={{ fontSize: "clamp(1.5rem, 2.5vw, 3rem)", fontWeight: 800 }}
                >
                    Animation to make you visualize
                    <br />
                    every concept
                </h1>
                <div className="video_container d-flex align-items-center justify-content-center">
                    <div className="video_inside">
                        <video src={videoToPlay} width={"100%"} height={"100%"} controls controlsList="nodownload">
                            <source src="movie.mp4" type="video/mp4" />
                            <source src="movie.ogg" type="video/ogg" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
                <div
                    className="d-flex flex-column flex-lg-row align-items-center justify-content-center pt-5 th_container"
                    style={{ gap: "1rem" }}
                >
                    {slidesData.map((item) => (
                        <button
                            key={item.id}
                            onClick={() => handleVideoImgeClick(item.video)}
                            className="buttonContainer"
                            style={{
                                backgroundImage: `url(${item.image})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                PointerEvent: videoToPlay === item.video ? "none" : "auto",
                            }}
                        >
                            <div className={`button_inside_play ${videoToPlay === item.video ? 'now_showing' : ''}`}>
                                {videoToPlay === item.video ? (
                                    <span className="text-white">Now Showing</span>
                                ) : (
                                    <img src={video_play_icon} alt="play" />
                                )}
                            </div>
                        </button>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Animations;
