import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

import spotlight_1 from '../assets/img/spotlight/spotlight_1.png'
import spotlight_2 from '../assets/img/spotlight/spotlight_2.png'
import spotlight_3 from '../assets/img/spotlight/spotlight_3.jpg'
import spotlight_4 from '../assets/img/spotlight/spotlight_4.png'
import spotlight_5 from '../assets/img/spotlight/spotlight_5.jpg'
import spotlight_6 from '../assets/img/spotlight/spotlight_6.png'
import spotlight_7 from '../assets/img/spotlight/spotlight_7.jpeg'
import spotlight_8 from '../assets/img/spotlight/spotlight_8.jpeg'

const images = [
  {
    src: spotlight_1,
    width: 3,
    height: 2,
    caption: "STEPapp implementation in EMRS pan India",
  },
  {
    src: spotlight_2,
    width: 2,
    height: 2,
    caption: "STEPapp",
  },
  {
    src: spotlight_3,
    original: spotlight_3,
    width: 2,
    height: 1,
    caption: "STEPapp",
  },
  {
    src: spotlight_4,
    original: spotlight_4,
    width: 2,
    height: 4,
    caption: "STEPapp",
  },
  {
    src: spotlight_5,
    width: 2,
    height: 2,
    caption: "STEPapp",
  },
  {
    src: spotlight_6,
    original: spotlight_6,
    width: 1.5,
    height: 1,
  },
  {
    src: spotlight_7,
    width: 1.5,
    height: 1,
  },
  {
    src: spotlight_8,
    width: 1.5,
    height: 1,
  },
];

const Spotlight = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  return (
    <div>
      <Gallery photos={images} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={images.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  )
}

export default Spotlight