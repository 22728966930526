import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import qatar_steel from '../assets/img/newUI/qatar_steel.png'
import stepskills from '../assets/img/newUI/stepskills.png'
import herowired from '../assets/img/newUI/herowired.png'
import nsdc from '../assets/img/newUI/nsdc.png'
import codeacademy from '../assets/img/newUI/codecademy.png'

import our_story from '../assets/img/newUI/our_story.png'
import Spotlight from './Spotlight';

const OurStory = () => {
    const settings = {
        dots: true,
        infinite: true,
        arrows: true,
        autoplay: false,
        speed: 3000,
        speed: 900,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const imageArray = [qatar_steel,
        stepskills,
        herowired,
        nsdc,
        codeacademy]

    return (
        <div className='container py-5' id='ourStory'>
            <div className='position-relative'>
                <img src={our_story} alt='image_story' className='w-100 h-50' style={{ objectFit: 'contain' }} />                <div className="w-md-50 d-none d-xl-block overflow_card">
                    <div className='p-5' style={{ borderRadius: '2rem', backgroundColor: '#4545AF', border: '1rem solid #FFF', maxWidth: '300px' }}>
                        <span style={{ textOverflow: 'wrap', color: '#FFF' }}>
                            STEPapp is the dedication of 400+ IITians and Doctors, their passion for teaching and perseverance of over a decade, that has blended conceptual learning into a gamified format.
                        </span>
                    </div>
                </div>
            </div>
            <div className='w-100 d-flex flex-column flex-md-row align-items-center justify-content-center px-5 pt-5' style={{ gap: '2rem' }}>
                <div className='text-left' style={{ maxWidth: '400px' }}>
                    <h1 className='headerText'>Our Story</h1>
                    <span style={{ color: '#7D7D7D', fontSize: 'clamp(1rem, 2.5vw, 1.5rem)' }}>The app has been developed by a team of experts that have spent over 24 years identifying and cultivating talent for competitive exams. Their deep insights of the education system have rendered a solution to complement and transform traditional classroom pedagogy. Electronic typesetting, remaining essentially unchanged.</span>
                </div>
                <div className="d-block d-xl-none w-md-50">
                    <div className='p-md-5 p-3'
                        style={{
                            borderRadius: '2rem',
                            backgroundColor: '#4545AF',
                            border: '1rem solid #FFF',
                            maxWidth: '300px'
                        }}
                    >
                        <span style={{ textOverflow: 'wrap', color: '#FFF' }}>
                            STEPapp is the dedication of 400+ IITians and Doctors, their passion for teaching and perseverance of over a decade, that has blended conceptual learning into a gamified format.
                        </span>
                    </div>
                </div>
                <div className='d-none d-lg-block'></div>
            </div>
            <div className='container'>
                <h1 className='headerText'>Our Mission</h1>
                <span style={{ color: '#7D7D7D', fontSize: 'clamp(1rem, 2.5vw, 1.5rem)' }}>The objective of STEPapp is to discover and reward ingenious students for their efforts and sincerity, irrespective of their socioeconomic strata. We strive to develop an education system that provides equal opportunities to everyone in our country.</span>
            </div>
            <div className='py-5 mt-5' style={{ borderTop: '1px solid #D4D4D4', borderBottom: '1px solid #D4D4D4' }}>
                <h1 className='text-center headerText '>Our Partners</h1>
                <div className='d-flex flex-wrap align-items-center justify-content-center flex-row'>
                    {imageArray.map((item) => (
                        <img src={item} alt='partner' />
                    ))}
                </div>
            </div>
            <div className='py-5 mt-5' style={{borderBottom: '1px solid #D4D4D4' }}>
            <h1 className='text-center headerText '>Spotlight</h1>
                <div className='py-3'>
                    <Spotlight />
                </div>
            </div>
        </div>
    )
}

export default OurStory