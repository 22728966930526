import React from 'react'
import Slider from "react-slick";
import mobile_app from '../assets/section_new_images/mobile_app.png'
import mobile2 from '../assets/section_new_images/mobile2.png'
import downloadApp from '../assets/section_new_images/downloadApp.png'
import quote_icon from '../assets/section_new_images/quote_icon.png'
import playstore from '../assets/section_new_images/playstore_img.webp'
import appstore from '../assets/section_new_images/appstore_img.webp'

import '../assets/section_new_styles/downloadApp.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../assets/section_new_styles/new_testimonial.scss'


const slidesData = [
    {
        id: 0,
        header: 'Revolutionizing your learning',
        subHeader: 'Download the app and make your kid fall in love with learning',
        mob_img: mobile_app,
        quote: "Our app allows you to track your child's performance through our refined analytics to ensure sustainable progress in all subject through gamified and engaging learning."
    },
    {
        id: 1,
        header: 'Learning made simple',
        subHeader: 'Download the app for a brighter academic experience',
        mob_img: mobile_app,
        quote: "A one stop solution to keep track of your kid's progress with our refined analytics to ensure sustainable progress in all subjects through gamified learning"
    },
    {
        id: 2,
        header: 'Learning made FUN',
        subHeader: 'Download the app to ensure a brighter future for your child',
        mob_img: mobile_app,
        quote: 'The best app to track your kid’s progress , solidify their fundamentals and make learning engaging for them.'
    }
]

const Quote = ({ quote }) => {
    return (
        <div className='d-flex' >
            <div className='d-flex align-items-start justify-content-end'>
                <div className='img_container' >
                    <img className='img-fluid' style={{ float: 'inline-end', height: '50px', width: '200px', objectFit: 'contain' }} src={quote_icon} />
                </div>
            </div>
            <div className='d-flex align-items-center'>
                <p className='px-0 py-5 app-text' style={{fontSize: 'clamp(1rem, 2.5vw, 1.5rem)', lineHeight: 'normal'}}>
                    {quote}
                </p>
            </div>
            <div className='d-flex align-items-end end_quote'>
                <div className='img_container' >
                    <img className='img-fluid' src={quote_icon} style={{height: '50px', width: '200px', objectFit: 'contain'    }} />
                </div>
            </div>

        </div>
    )
}

const Items = ({ item, index }) => {

    return (
        <div className='app-items row px-md-0 px-3'>
            <div className='col-md-6 col-12'>
                <div className='py-2 text-center text-md-left'>
                    <h1 className='font-weight-bold' style={{fontSize: 'clamp(2rem, 2.5vw, 3.5rem)', fontWeight: 800}}>{item.header}</h1>
                    <h3 style={{fontSize: 'clamp(1rem, 1.5vw, 2rem)', fontWeight: 500, color: '#FF6D28'}}>{item.subHeader}</h3>
                </div>
                <div className='py-2 d-flex align-items-center justify-content-center justify-content-md-start' style={{gap: '1.5rem'}}>
                    {/* <div >
                        <img className='img-fluid h-100' src={downloadApp} />
                    </div> */}
                    <a href='https://play.google.com/store/apps/details?id=com.EduIsFun.EduIsFun&pli=1' target='_blank'><img src={playstore} alt='Play Sore' className='img-fluid' style={{borderRadius: '10px'}} /></a>
                    <a href='https://apps.apple.com/in/app/step-gamified-learning/id1457941148' target='_blank'><img src={appstore} alt='App Sore' className='img-fluid' style={{borderRadius: '10px'}} /></a>
                </div>
                <div className='py-2'>
                    <Quote quote={item.quote} />
                </div>
            </div>
            <div className='col-md-6 col-12'>
                <div>
                    <img className='img-fluid' style={{height: '500px'}} src={item.mob_img} />
                </div>
            </div>
        </div>
    )
}

const DownloadApp = () => {
    return (
        <section className='my-5 download-section' id='downloadApp'>
            <div className='container'>
                <Slider {...{
                    dots: true,
                    infinite: true,
                    speed: 1,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }}>

                    {slidesData.map((item, index) => (
                        <div>
                            <Items item={item} index={index} />
                        </div>
                    ))}



                </Slider>
                {/* <Items /> */}
            </div>
        </section>
    )
}

export default DownloadApp