import React from "react";
import india_map from "../assets/img/india_map.png";
import low_and_secure from "../assets/img/newUI/low_and_secure.png";
import enroll_1 from "../assets/img/newUI/enroll_1.png";
import enroll_2 from "../assets/img/newUI/enroll_2.png";
import enroll_3 from "../assets/img/newUI/enroll_3.png";
import { Link } from "gatsby";

const PartnerWith = () => {
  return (
    <div
      className="container pt-5"
      style={{ borderbottom: "1px solid #D4D4D4" }}
      id='partners'
    >
      <div className="d-flex flex-column align-items-center justify-content-center">
        <h1
          className="headerText"
          style={{ fontSize: "clamp(1.5rem, 3.5vw, 3rem)" }}
        >
          Partner with Stepapp
        </h1>
        <span
          style={{
            color: "#FF6D28",
            fontSize: "clamp(14px, 2.5vw, 24px)",
            fontWeight: 500,
          }}
        >
          Become an enrollment partner !
        </span>
        <Link to='/#contact'
          className="font-weight-bold text-white px-4 py-2 my-4"
          style={{
            maxWidth: "150px",
            borderRadius: "40px",
            border: "none",
            backgroundColor: "#4545AF",
          }}
        >
          Let’s Connect
        </Link>
      </div>
      <div className="row d-flex flex-column flex-md-row align-items-center justify-content-around">
        <div className="col-md-6">
          <img src={india_map} alt="flag" className="w-100" />
        </div>
        <div className="col-md-6">
          <div className="india_map_right_side">
            <SingleLineContainer
              text="Low and secure investment"
              image={low_and_secure}
            />
            <SingleLineContainer text="High returns" image={low_and_secure} />
            <SingleLineContainer
              text="Proven successful model"
              image={low_and_secure}
            />
            <SingleLineContainer
              text="No infrastructure required"
              image={low_and_secure}
            />
            <SingleLineContainer
              text="Branding and marketing support"
              image={low_and_secure}
            />
            <SingleLineContainer
              text="Tech support and training"
              image={low_and_secure}
            />
            <SingleLineContainer
              text="High scalability"
              image={low_and_secure}
            />
          </div>
        </div>
      </div>
      <div
        className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-around justify-content-md-center pt-5 pt-md-0 px-5"
        style={{ gap: "1rem" }}
      >
        <SingleDIV image={enroll_1} text="90+ Enrollment Partner" />
        <SingleDIV image={enroll_2} text="33 Upcoming" />
        <SingleDIV image={enroll_3} text="70+ New Enrollment Partners" />
      </div>
    </div>
  );
};

export default PartnerWith;

const SingleLineContainer = ({ text, image }) => {
  return (
    <div
      className="px-3 py-2 d-flex align-items-center"
      style={{ gap: "1rem", backgroundColor: "#FF6D28", borderRadius: 20 }}
    >
      <img
        src={image}
        alt="here"
        style={{ width: "44px", height: "44px", objectFit: "contain" }}
      />
      <span className="text-white">{text}</span>
    </div>
  );
};

const SingleDIV = ({ image, text }) => {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ gap: "1rem" }}
    >
      <img
        src={image}
        alt="image"
        style={{ width: "3rem", height: "3rem", objectFit: "contain" }}
      />
      <span style={{ color: "#3D3D3D", fontSize: "1rem", fontWeight: 600 }}>
        {text}
      </span>
    </div>
  );
};
