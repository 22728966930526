import React from 'react'
import amitabh from '../assets/section_new_images/amitabh.png'
import concepts from '../assets/section_new_images/concepts.png'
import schools from '../assets/section_new_images/schools.png'
import students from '../assets/section_new_images/students.png'
import section1_img from '../assets/section_new_images/section1.png'


const WhyChoosUs = () => {
    return (
        <section className='d-flex justify-content-center py-5' id='whyChooseUs'>
            <div className='container row py-3'>
                <div className='col-md-5 col-12 left-side'>
                    <div className='d-flex justify-content-around flex-column text-center h-100'>
                        <div>
                            <span className='text-black' style={{fontSize: 'clamp(1.5rem, 2.5vw, 3rem)', fontWeight: 800}}>Why Choose Us !</span>
                        </div>
                        <div className='image-section'>
                            <img src={amitabh} className='img-fluid' style={{objectFit: 'contain'}} />
                        </div>
                        <div>
                            <div className='d-flex justify-content-start align-items-center'>
                                <img src={concepts} className='img-fluid h-100 m-2' /> <h5 className=''>20k + concepts mastered</h5>
                            </div>
                            <div className='d-flex justify-content-start align-items-center'>
                                <img src={schools} className='img-fluid h-100 m-2' /> <h5 >1500+ schools reached</h5>
                            </div>
                            <div className='d-flex justify-content-start align-items-center'>
                                <img src={students} className='img-fluid h-100 m-2' /> <h5 >20L+ students engaged</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-7 col-12 right-side'>
                    <div className='d-flex justify-content-center p-md-5 p-0' >
                        <img src={section1_img} className='img-fluid h-100' />
                    </div>
                </div>

            </div>
        </section>)
}

export default WhyChoosUs