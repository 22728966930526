import React from 'react'
import create_progress_left from '../assets/img/newUI/create_progress_left.png'
import '../assets/scss/createProgress.scss'

const CreateProgress = () => {
    return (
        <div className='createProgress_container container py-0 py-md-5' id='contact'>
            <div className='createProgress_textContainer'>
                <p>Let's create progress together !</p>
                <span>Our team would love to hear from you !</span>
            </div>
            <div className='createProgress_interested_in d-flex flex-column flex-md-row align-items-center justify-content-center'>
                <span className='font-weight-bold'>Interested in :</span>
                <div className='row flex-wrap flex-md-nowrap align-items-center justify-content-center interested_in_four_container'>
                    <SingleTextContianer text='Stepapp Plans' bgColor='#FAE0F3' textColor='#ED77D0' />
                    <SingleTextContianer text='Franchise' bgColor='#D0FDE0' textColor='#0D8036' />
                    <SingleTextContianer text='School solutions' bgColor='#FCFFA6' textColor='#9B9F19' />
                    <SingleTextContianer text='Any other' bgColor='#E0E9FA' textColor='#4545AF' />
                </div>
            </div>
            <div className='createProgress_third_container row my-5 px-3'>
                <div className='col-md-6 d-flex align-items-center justify-content-center' style={{alignSelf: 'center'}}>
                    <img src={create_progress_left} style={{maxWidth: '400px'}} />
                </div>
                <form className='col-md-6 d-flex flex-column createProgress_third_container_input'>
                    <SingleInputContainer labelText='Name' />
                    <SingleInputContainer labelText='Email ID' />
                    <SingleInputContainer labelText='Contact Number' />
                    <div className='d-flex flex-column py-3'>
                        <label className='mb-0'>Select Plan</label>
                        <select required>
                            <option selected disabled label='Select'></option>
                            <option>Individual Plan</option>
                            <option>School/Organisation Plan</option>
                            <option>Franchise</option>
                            <option>Others</option>
                        </select>
                    </div>
                    <SingleInputContainer labelText='Tell us about your query' />
                    <button type='submit' className='text-white px-4 py-2 border-none' style={{backgroundColor: '#4545AF', borderRadius: '40px', border: 'none', maxWidth: '130px'}}>
                        Submit
                    </button>
                </form>
            </div>
        </div>
    )
}

export default CreateProgress

const SingleTextContianer = ({ text, bgColor, textColor }) => {
    return (
        <div
            className='px-4 py-2'
            style={{ backgroundColor: bgColor, color: textColor, borderRadius: '40px', fontSize: 'clamp(14px, 1.5vw, 20px)', fontWeight: 600 }}>{text}</div>
    )
}

const SingleInputContainer = ({ labelText }) => {
    return (
        <div className='d-flex flex-column py-3'>
            <label className='mb-0'>{labelText}</label>
            <input required />
        </div>
    )
}