import React from 'react'
import bd_seymour from '../assets/img/newUI/bd_seymour.png'
import deepak_parekh from '../assets/img/newUI/deepak_parekh.png'
import piyush_pandey from '../assets/img/newUI/piyush_pandey.png'
import '../assets/section_new_styles/industryLeaders.scss'


const IndustryLeaders = () => {
    return (
        <section className='d-flex justify-content-center industry-leader py-5' id='industryLeaders'>

            <div className='container row py-3 position-relative '>
                <div className='w-100 d-flex justify-content-center mb-2'>
                    <h1 className='font-weight-bold text-center' style={{ fontSize: 'clamp(1.5rem, 2.5vw, 3rem)', fontWeight: 800 }}>What industry leaders say ?</h1>
                </div>

                {/* Industry Leader 1 */}
                <div className='row' >
                    <div className='col-md-8 col-9 p-2 pb-5 pt-3' style={{ background: '#D0FDE0', zIndex: 1, borderRadius: 50, boxShadow: '0px 8px 8px 0px rgba(130, 235, 167, 0.18)' }}>
                        <div className='row pt-3'>
                            <div className='col-md-4 col-12 d-flex align-items-center justify-content-center' >
                                <img src={deepak_parekh} width={180} style={{borderRadius: '20px'}} />
                            </div>
                            <div className='col-md-8 col-12 '>
                                <div>
                                    <h3 className='text-center text-md-left' style={{fontSize: '1.4rem', fontWeight: 700}}>Mr. Deepak S Parekh</h3>

                                    <h5 className='text-center text-md-left' style={{fontSize: '1.2rem'}}>Chairman HDFC Bank</h5>

                                    <p className='text-center text-md-left' style={{ fontSize: '1rem' }}>"The education system needs to become more storytelling and gaming to make it enjoyable and effective. Edu Is Fun and STEP app are doing a great job. Best of luck to them and Praveen."</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 col-3'></div>
                </div>

                {/* Industry Leader 2 */}
                <div className='row center-box' style={{position: 'relative', zIndex: 9}}>
                    <div className='col-md-4 col-3'></div>
                    <div className='col-md-8 col-9  p-2 pb-3 pt-3' style={{ background: '#FFF', zIndex: 2, borderRadius: 50, boxShadow: ' 0px 8px 8px 0px rgba(216, 222, 78, 0.12)' }}>
                        <div className='row pb-5 pt-4 mx-2' style={{ background: '#FBFDD1', borderRadius: 50, }}>
                            <div className='col-md-4 col-12 d-flex align-items-center justify-content-center' >
                                <img src={piyush_pandey} width={180} style={{borderRadius: '20px'}} />
                            </div>
                            <div className='col-md-8 col-12 pl-4'>
                                <div>
                                    <h3 className='text-center text-md-left' style={{fontSize: '1.4rem', fontWeight: 700}}>Mr. Piyush Pandey</h3>

                                    <h5 className='text-center text-md-left' style={{fontSize: '1.2rem'}}>Executive Chairman, OGILVY</h5>

                                    <p className='text-center text-md-left' style={{ fontSize: '1rem' }}>"STEPapp revolutionizes education in India by offering personalized and gamified learning, trackling the challenge of quality education for a vast population with limited resources."</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                {/* Industry Leader 3 */}
                <div className='row' style={{position: 'relative', zIndex: 100}}>
                    <div className='col-md-8  col-9 pr-4 py-3' style={{ background: '#FFF', zIndex: 3, borderRadius: 50, boxShadow: '0px 8px 8px 0px rgba(224, 152, 205, 0.12)' }}>
                        <div className='row py-5' style={{ background: '#FAE0F3', borderRadius: 50, }}>
                            <div className='col-md-4 col-12 d-flex align-items-center justify-content-center' >
                                <img src={bd_seymour} width={180} style={{borderRadius: '20px'}} />
                            </div>
                            <div className='col-md-8 col-12 '  >
                                <div>
                                    <h3 className='text-center text-md-left' style={{fontSize: '1.4rem', fontWeight: 700}}>Mr. BD Seymour</h3>

                                    <h5 className='text-center text-md-left' style={{fontSize: '1.2rem'}}>GD Somani Principal</h5>

                                    <p className='text-center text-md-left' style={{ fontSize: '1rem' }}>"The app is game-changing way to educate children, turning education into Edu-Nation. A blessing for the nation, thanks to the gamified learning."</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4 col-3'></div>
                </div>
            </div>
        </section>)
}

export default IndustryLeaders