import React from 'react'
import Slider from "react-slick";
import '../assets/section_new_styles/downloadApp.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import dashbboard_1 from '../assets/section_new_images/dashboard_1.webp'
import dashbboard_2 from '../assets/section_new_images/dashboard_2.webp'
import dashbboard_3 from '../assets/section_new_images/dashboard_3.webp'
import dashbboard_4 from '../assets/section_new_images/dashboard_4.webp'


const slidesData = [
    {
        id: 0,
        img_src: dashbboard_1,
        header: "One dashboard for student to track their progress",
        subheader: "Keep track of your progress 24X7",
        bg: '#FAE0F3',
        textCol: '#812167'
    },
    {
        id: 1,
        img_src: dashbboard_2,
        header: "Teachers can monitor the performance and efforts of their students",
        subheader: "Track your class' progress and activities in real time.",
        bg: '#D0FDE0',
        textCol: '#1B8B43'
    },
    {
        id: 2,
        img_src: dashbboard_3,
        header: "Easy way for Principal to stay apprised of students' performance",
        subheader: "A unified dashboard for tracking the academic progress of your school",
        bg: '#FFD9C5',
        textCol: '#975735'
    },
    {
        id: 3,
        img_src: dashbboard_4,
        header: "All updates on partner schools for Policymakers",
        subheader: "Stay up-to-date with school and student progress across the country, state, and city",
        bg: '#FBFDD1',
        textCol: '#5F6207'
    }
]



const Items = ({ item, index }) => {

    return (

        <div className='row'>
            <div className='col-md-6 col-12 py-2 py-md-5'>
                <div className='image-section my-2 '>
                    <img src={item.img_src} className='img-fluid' />
                </div>
            </div>

            <div className='col-md-6 col-12 d-flex align-items-center'>
                <div className='p-3 p-md-5 my-3' style={{
                    borderRadius: 40,
                    background: item.bg
                }}>
                    <div className='text-md-left text-center'>
                        <div className='py-2'>
                            <h1 className='font-weight-bold' style={{ color: item.textCol, fontSize: 'clamp(1.5rem, 2.5vw, 2.5rem)'}}>{item.header}</h1>
                        </div>
                        <div className=' py-3' >
                            <span style={{ color: '#3D3D3D',fontSize: 'clamp(1rem, 2.5vw, 1.5rem)' }}>{item.subheader}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

const Dashboard = () => {
    return (

        <section className='d-flex justify-content-center industry-leader py-5' id='dashboard'>
            <div className='container d-flex flex-column px-4 py-3'>
                <div>
                    <h1 className='text-center text-black' style={{ fontSize: 'clamp(1.5rem, 2.5vw, 3rem)', fontWeight: 800 }}>Monitoring through Intensive dashboard</h1>
                </div>
                <Slider {...{
                    dots: true,
                    infinite: true,
                    speed: 1,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }}>

                    {slidesData.map((item, index) => (
                        <div>
                            <Items item={item} index={index} />
                        </div>
                    ))}



                </Slider>
            </div>
        </section>
    )
}

export default Dashboard